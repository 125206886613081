import { CssBaseline } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import styled, { createGlobalStyle } from "styled-components";
import { getSidebarOpen, setSidebarOpen } from "../api/localStorage";
import ErrorsApi from "./ErrorsApi";
import Header from "./components/Header";
import Sidebar from "./components/Sidebar/Sidebar";

// #region Customisation de certains éléments
const Root = styled.div`
	display: flex;
`;

const Main = styled.main`
	flex-grow: 1;
`;

const GlobalStyle = createGlobalStyle`
  body {
    background: ${(props) => props.theme.body.background};
  }
`;

const useStyles = makeStyles((theme) => ({
	toolbar: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		padding: theme.spacing(0, 1),
		// necessary for content to be below app bar
		...theme.mixins.toolbar,
	},
	main: {
		padding: theme.spacing(5) + "px",
		[theme.breakpoints.up("lg")]: {
			padding: theme.spacing(10) + "px",
		},
	},
}));
// #endregion Customisation de certains éléments

export default function Dashboard({ children }) {
	const classes = useStyles();
	const [open, setOpen] = React.useState(getSidebarOpen());

	const setOpenAndSave = (state) => {
		setOpen(state);
		setSidebarOpen(state);
	};

	return (
		<>
			<Root>
				<CssBaseline />
				<GlobalStyle />
				<ErrorsApi />

				<Header open={open} setOpen={setOpenAndSave} />

				<Sidebar open={open} setSidebarOpen={setOpenAndSave} />

				<Main className={classes.main} style={{ minHeight: "100vh" }}>
					<div className={classes.toolbar} />
					{children}
				</Main>
			</Root>
		</>
	);
}
