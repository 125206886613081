import { grey } from "@material-ui/core/colors";

const primary = "#9D0718";
const secondary = "#26262C";

const lightTheme = {
	name: "DPS",
	spacing: 4,
	palette: {
		primary: {
			main: primary,
			contrastText: "#FFF",
		},
		secondary: {
			main: secondary,
			contrastText: "#FFF",
		},
	},
	header: {
		color: grey[500],
		background: "#FFF",
		search: {
			color: grey[800],
		},
		indicator: {
			background: secondary,
		},
	},
	sidebar: {
		width: 260,
		color: "#FFF",
		background: secondary,
		header: {
			color: "#FFF",
			background: secondary,
			brand: {
				color: "#3a4244",
			},
		},
		footer: {
			color: "#FFF",
			background: secondary,
			online: {
				background: "#FFF",
			},
		},
		category: {
			fontWeight: 400,
		},
		badge: {
			color: "#000",
			background: "#FFF",
		},
	},
	body: {
		background: "#FAFAFA",
	},
	footer: {
		color: grey[800],
	},
};

const darkTheme = {
	name: "DPS",
	spacing: 4,
	palette: {
		primary: {
			main: "#333",
			contrastText: "#EEE",
		},
		secondary: {
			main: "#333",
			contrastText: "#EEE",
		},
	},
	header: {
		color: "#EEE",
		background: "#333",
		search: {
			color: "#EEE",
		},
		indicator: {
			background: "#333",
		},
	},
	sidebar: {
		width: 260,
		color: "#EEE",
		background: "#333",
		header: {
			color: "#EEE",
			background: "#333",
			brand: {
				color: "#EEE",
			},
		},
		footer: {
			color: "#EEE",
			background: "#333",
			online: {
				background: "#EEE",
			},
		},
		category: {
			fontWeight: 400,
		},
		badge: {
			color: "#333",
			background: "#EEE",
		},
	},
	body: {
		background: "#333",
	},
	footer: {
		color: "#EEE",
	},
};

export default [lightTheme, darkTheme];
