import { Tooltip } from "@material-ui/core";
import { camelCase } from "lodash";
import React from "react";
import ReactExport from "react-export-excel";
import { Download as DownloadIcon } from "react-feather";
import { useTranslation } from "react-i18next";
import { IconButton } from "../Buttons";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export default function ExportExcel(props) {
	const { t } = useTranslation();
	const { values, headers, title } = props;

	return (
		<Tooltip title={t("translation:tableExport")}>
			<ExcelFile filename={title} element={<IconButton title={t("translation:tableExport")} icon={<DownloadIcon />} />}>
				<ExcelSheet data={values} name={title}>
					{headers
						.filter((item) => item.id !== "" && item.active !== false && item.type !== "custom")
						.map((header) => {
							return <ExcelColumn key={header.id} label={t(camelCase(header.id))} value={header.id} />;
						})}
				</ExcelSheet>
			</ExcelFile>
		</Tooltip>
	);
}
