import {
	Button,
	ButtonGroup,
	FormControlLabel,
	Link,
	makeStyles,
	Breadcrumbs as MuiBreadcrumbs,
	Divider as MuiDivider,
	Grid as MuiGrid,
	Typography as MuiTypography,
	Radio,
	RadioGroup,
	withWidth,
} from "@material-ui/core";
import {
	AddBox as AddIcon,
	Edit as EditIcon,
	InsertDriveFile as InsertDriveFileIcon,
	Save as SaveIcon,
	Send as SendIcon,
	Backup as UploadIcon,
	RemoveRedEye as ViewIcon,
} from "@material-ui/icons";
import { display, spacing } from "@material-ui/system";
import PropTypes from "prop-types";
import React, { useState } from "react";
import Helmet from "react-helmet";
import { useTranslation } from "react-i18next";
import { NavLink as RouterNavLink } from "react-router-dom";
import styled from "styled-components";
import {
	BaseDialog,
	Collapse,
	DocumentViewer,
	EnhancedTable,
	IconButton,
	LoadingButton,
	LoadingButtonWithIcon as MyButton,
	Tooltip,
	useCustomSnackbar,
} from ".";
import { _arrayBufferToBase64 } from "../../api/files";

const NavLink = React.forwardRef((props, ref) => <RouterNavLink innerRef={ref} {...props} />);

const Grid = styled(MuiGrid)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Typography = styled(MuiTypography)(display);
const LoadingButtonWithIcon = styled(MyButton)(spacing);

// On rends les boutons d'action de la page reponsif en cachant le texte sur les petits écrans
const useStyles = makeStyles((theme) => ({
	btn: {
		[theme.breakpoints.down("md")]: {
			"& .text": {
				display: "none",
			},
			"& .MuiButton-startIcon": {
				marginRight: 0,
			},
		},
	},
}));

function DetailsPage({ title, subtitle, breadcrumbs, formik, header, children, actions, GEDRows, documentViewerURL }) {
	const { t } = useTranslation();
	const snackbar = useCustomSnackbar();
	const classes = useStyles();

	// #region Dialog de GED
	const [openGED, setOpenGED] = useState(false);
	const [uploadOpen, setUploadOpen] = useState(false);
	const [headCells, setHeadCells] = useState([
		{ id: "DocType", primary: true },
		{ id: "DocName", primary: true },
		{ id: "DocDescription" },
		{
			id: "actions",
			type: "custom",
			content: ({ row }) => (
				<ButtonGroup color="primary">
					{/* Ouverture */}
					<IconButton icon={<ViewIcon />} onClick={() => window.open(row.URL)} disabled={!row?.SecurityOpen || !row?.URL} />
					{/* Upload */}
					<IconButton icon={<UploadIcon />} onClick={() => setUploadOpen(true)} disabled={!row?.SecurityUpload} />
					{/* Envoie par mail */}
					<IconButton icon={<SendIcon />} disabled={!row?.SecuritySend || !row?.URL} />
					{/* Signature electronique */}
					<IconButton icon={<EditIcon />} disabled={!row?.SecuritySigning || !row?.URL} />
				</ButtonGroup>
			),
		},
	]);
	const [replaceFile, setReplaceFile] = useState("merge"); // faux = fusionner, vrai = remplacer
	const [fileToUpload, setFileToUpload] = useState();
	const [uploadLoading, setUploadLoading] = useState(false);
	// #endregion Dialog de GED

	return (
		<React.Fragment>
			<Helmet title={title} />

			{/* En-tête */}
			<Grid container justify="space-between" alignItems="center">
				{/* Titre principal */}
				<Grid item>
					<Typography variant="h3" gutterBottom display="block">
						{title}
					</Typography>
					{/* Sous-titre */}
					{subtitle && (
						<Typography variant="h5" gutterBottom display="block">
							{subtitle}
						</Typography>
					)}
				</Grid>

				{/* Boutons d'actions */}
				<Grid item className={classes.btn}>
					{/* Bouton personnalisés */}
					{actions?.map((action) => {
						if (!action) return null;
						if (typeof action != "object") return null;
						if (action?.type === "custom") {
							return (
								<Tooltip title={t(action?.name)} key={action?.name}>
									{React.cloneElement(action?.content)}
								</Tooltip>
							);
						}
						return (
							<Tooltip key={action.name} title={t(action.name)}>
								<LoadingButtonWithIcon
									loading={action.loading}
									disabled={action?.disabled}
									icon={action.icon}
									iconPosition="start"
									onClick={action.onClick}
									color="primary"
									variant="contained"
									ml={2}
								>
									<span className="text">{t(action.name)}</span>
								</LoadingButtonWithIcon>
							</Tooltip>
						);
					})}

					{/* Formulaire de création, désactivé pour le moment */}
					{false && (
						<Tooltip title={t("translation:new")}>
							<LoadingButtonWithIcon loading={false} icon={<AddIcon />} iconPosition="start" color="primary" variant="contained" ml={2}>
								<span className="text">{t("translation:new")}</span>
							</LoadingButtonWithIcon>
						</Tooltip>
					)}

					{GEDRows && (
						<Tooltip title={t("translation:documents")}>
							<LoadingButtonWithIcon
								loading={false}
								icon={<InsertDriveFileIcon />}
								iconPosition="start"
								onClick={() => setOpenGED(true)}
								color="primary"
								variant="contained"
								ml={2}
							>
								<span className="text">{t("translation:documents")}</span>
							</LoadingButtonWithIcon>
						</Tooltip>
					)}

					{/* Bouton de sauvegarde Formik, désactivé pour le moment */}
					{!!formik && (
						<Tooltip title={t("translation:save")}>
							<LoadingButtonWithIcon
								loading={formik?.isSubmitting}
								icon={<SaveIcon />}
								iconPosition="start"
								onClick={formik?.handleSubmit}
								disabled={!formik?.dirty}
								color="primary"
								variant="contained"
								ml={2}
							>
								<span className="text">{t("translation:save")}</span>
							</LoadingButtonWithIcon>
						</Tooltip>
					)}
				</Grid>
			</Grid>

			{/* Fil d'Ariane */}
			{breadcrumbs && (
				<Breadcrumbs aria-label="Breadcrumb" mt={2}>
					{breadcrumbs.map((crumb) => (
						<Link key={crumb.url} component={NavLink} exact to={crumb.url}>
							{crumb.label}
						</Link>
					))}
					<Typography>{t("translation:details")}</Typography>
				</Breadcrumbs>
			)}

			{header}

			{/* Dialog de GED */}
			<BaseDialog open={openGED} setOpen={setOpenGED} title="myDocuments" maxWidth="xl" fullWidth>
				<EnhancedTable id="gedTable" headCells={headCells} setHeadCells={setHeadCells} rows={GEDRows} noRefetch />
			</BaseDialog>

			{/* Dialog choix du fichier à uploader */}
			<BaseDialog
				open={uploadOpen}
				setOpen={setUploadOpen}
				title={replaceFile ? "replaceDocument" : "mergeDocument"}
				maxWidth="xs"
				fullWidth
				actions={
					<LoadingButton
						variant="contained"
						color="secondary"
						disabled={!fileToUpload}
						loading={uploadLoading}
						onClick={() => {
							setUploadLoading(true);
							setTimeout(() => {
								setUploadLoading(false);
								setUploadOpen(false);
								setFileToUpload();
								snackbar.showSuccess(t("translation:fileUploadSuccess"));
							}, 1500);
						}}
					>
						{t(replaceFile)}
					</LoadingButton>
				}
			>
				<Button variant="contained" component="label">
					{fileToUpload?.FileName || t("translation:chooseFile")}
					<input
						hidden
						type="file"
						accept=".png,.jpg,.pdf"
						onChange={(event) => {
							const [file] = event.target.files;

							if (file) {
								var reader = new FileReader();
								reader.readAsArrayBuffer(file);
								reader.onload = function (evt) {
									setFileToUpload({
										...fileToUpload,
										PictureBytes: _arrayBufferToBase64(evt.target.result),
										FileName: file.name,
									});
								};
								reader.onerror = function (err) {
									console.error(err);
								};
							}
						}}
					/>
				</Button>
				<Grid component="label" container alignItems="center" spacing={1}>
					<RadioGroup row name="replace" value={replaceFile} onChange={(evt) => setReplaceFile(evt.target.value)}>
						<FormControlLabel value="merge" control={<Radio />} label={t("translation:merge")} />
						<FormControlLabel value="replace" control={<Radio />} label={t("translation:replace")} />
					</RadioGroup>
				</Grid>
			</BaseDialog>

			<Divider my={6} />

			{/* Corps de page */}
			<Grid container spacing={2}>
				{/* Contenu de la page (dynamique) */}
				<Grid item xs={12} md={documentViewerURL ? 6 : null}>
					{children}
				</Grid>
				{/* Aperçu d'un document, si spécifié */}
				{documentViewerURL && (
					<Grid item xs={12} md={6}>
						<Collapse model="detailsPage" title="DocumentViewer" expandByDefault expandDisabled>
							<DocumentViewer
								url={documentViewerURL}
								onError={(error) => {
									console.log(error);
								}}
							/>
						</Collapse>
					</Grid>
				)}
			</Grid>
		</React.Fragment>
	);
}

DetailsPage.propTypes = {
	title: PropTypes.string.isRequired,
	breadcrumbs: PropTypes.arrayOf(
		PropTypes.shape({
			url: PropTypes.string.isRequired,
			label: PropTypes.string.isRequired,
		})
	),
	formik: PropTypes.object,
	header: PropTypes.node,
	actions: PropTypes.arrayOf(
		PropTypes.shape({
			name: PropTypes.string.isRequired,
			icon: PropTypes.node.isRequired,
			loading: PropTypes.bool.isRequired,
			disabled: PropTypes.bool,
			onClick: PropTypes.func.isRequired,
		})
	),
};

export default withWidth()(DetailsPage);
