// Déclaration statique de tout les fichiers JSON pour que le compilateur React les mettent dans le bundle final

import countries from "./LangCountries.json";
import currencies from "./LangCurrencies.json";
import dashboard from "./LangDashboard.json";
import files from "./LangFiles.json";
import ged from "./LangGED.json";
import general from "./LangGeneral.json";
import login from "./LangLogin.json";
import settings from "./LangSettings.json";
import suspects from "./LangSuspects.json";
import languages from "./Languages.json";

export default {
	langs: {
		FRA: "fr",
		ENU: "en",
	},
	namespaces: {
		countries,
		currencies,
		dashboard,
		files,
		ged,
		general,
		login,
		settings,
		suspects,
		languages,
	},
};
