import { isEqual } from "lodash";
import React, { createContext, useMemo, useState } from "react";
import { parseJwt, removeToken, setToken } from ".";

// #region Contexte de l'utilisateur
export const UserContext = createContext();

const MemoizedUserProvider = React.memo(
	({ children, value }) => {
		return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
	},
	(prevProps, nextProps) => {
		return isEqual(prevProps.value.user, nextProps.value.user);
	}
);

export const UserProvider = ({ children }) => {
	const [user, setUserState] = useState(parseJwt());

	function setUser(user) {
		if (user === null) {
			removeToken();
			setUserState(null);
		} else {
			setToken(user);
			setUserState((prevUser) => ({ ...prevUser, ...user }));
		}
	}

	const providerValue = useMemo(() => ({ user, setUser }), [user]);

	return <MemoizedUserProvider value={providerValue}>{children}</MemoizedUserProvider>;
};
// #endregion Contexte de l'utilisateur
