import { Collapse, isWidthUp, makeStyles, withWidth } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PerfectScrollbar from "react-perfect-scrollbar";
import { NavLink, useHistory } from "react-router-dom";
import styled from "styled-components";
import { pascalCase } from "../../../api/strings";
import { Tooltip } from "../../../pages/components";
import routes from "../../../routes";
import "../../../vendor/perfect-scrollbar.css";
import SidebarCategory from "./SidebarCategory";
import SidebarLink from "./SidebarLink";
import SidebarSection from "./SidebarSection";

const useStyles = makeStyles((theme) => ({
	mobileDrawer: {
		width: "100%",
	},
	drawer: {
		width: theme.sidebar.width,
		flexShrink: 0,
		whiteSpace: "nowrap",
	},
	drawerOpen: {
		width: theme.sidebar.width,
		transition: theme.transitions.create("width", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	drawerClose: {
		transition: theme.transitions.create("width", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		overflowX: "hidden",
		width: "50px",
	},
	toolbar: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		padding: theme.spacing(0, 1),

		backgroundColor: theme.sidebar.background,
		boxShadow: "0 0 10px rgba(0, 0, 0, 0.5)",
		zIndex: 1,
		// necessary for content to be below app bar
		...theme.mixins.toolbar,
	},
	logo: {
		maxWidth: "250px",
		maxHeight: "55px",
	},
	listItemOpen: {},
	listItemClose: {
		// Quand le menu est fermé, on affiche que l'icône de la catégorie
		"& .MuiListItemText-root, & .MuiListItemText-root + .MuiSvgIcon-root": {
			display: "none",
		},
	},
	listItemIconOpen: {
		minWidth: "35px",
		color: "inherit",
		alignSelf: "flex-start",
	},
	listItemIconClose: {
		minWidth: "35px",
		color: "inherit",
		marginLeft: "-5px",
	},
	listItemTextOpen: {
		margin: 0,
		whiteSpace: "normal",
	},
	divider: {
		backgroundColor: theme.sidebar.color,
		opacity: 0.5,
	},
	hidden: {
		display: "none !important",
	},
}));

const Scrollbar = styled(PerfectScrollbar)`
	background-color: ${(props) => props.theme.sidebar.background};
`;

const Items = styled.div`
	color: ${(props) => props.theme.sidebar.color};
`;

function MobileDrawer({ children, sidebarOpen, setSidebarOpen }) {
	const classes = useStyles();

	const [open, setOpen] = useState(false);

	useEffect(() => {
		setOpen(sidebarOpen);
	}, [sidebarOpen]);

	function onClose() {
		setOpen(false);
		setSidebarOpen(false);
	}

	function onOpen() {
		setOpen(true);
		setSidebarOpen(true);
	}

	return (
		<SwipeableDrawer className={classes.mobileDrawer} anchor="left" open={open} onClose={onClose} onOpen={onOpen}>
			{children}
		</SwipeableDrawer>
	);
}

const ResponsiveDrawer = withWidth()(function ({ width, children, open, setSidebarOpen }) {
	const classes = useStyles();
	const isDesktop = isWidthUp("sm", width);

	if (isDesktop) {
		return (
			<Drawer
				variant="permanent"
				className={clsx(classes.drawer, {
					[classes.drawerOpen]: open,
					[classes.drawerClose]: !open,
				})}
				classes={{
					paper: clsx({
						[classes.drawerOpen]: open,
						[classes.drawerClose]: !open,
					}),
				}}
			>
				{children}
			</Drawer>
		);
	} else {
		return (
			<MobileDrawer sidebarOpen={open} setSidebarOpen={setSidebarOpen}>
				{children}
			</MobileDrawer>
		);
	}
});

export default function Sidebar({ open, setSidebarOpen }) {
	const { t } = useTranslation();
	const history = useHistory();
	const classes = useStyles();

	// #region Initialisation de la liste des routes
	// Returns bool[]
	function initOpenRoutes() {
		// Ouvre les collapse qui contiennent la page active
		const pathName = window.location.pathname;

		return routes.map((route) => {
			const isActive = pathName.indexOf(route.path) === 0;
			const isOpen = route.open || false;
			const isHome = route.containsHome && pathName === "/" ? true : false;

			let hasActiveChild = false;
			if (Array.isArray(route?.children)) {
				hasActiveChild = route.children.some((route) => pathName.indexOf(route?.path) === 0);
			}

			// console.log({ route, isActive, hasActiveChild, isOpen, isHome });
			return isActive || hasActiveChild || isOpen || isHome;
		});
	}

	const [openRoutes, setOpenRoutes] = useState(() => initOpenRoutes());
	// #endregion Initialisation de la liste des routes

	function toggleOpenRoute(index) {
		setOpenRoutes((routes) => routes.map((route, i) => (i === index ? !route : route)));
	}

	return (
		<ResponsiveDrawer open={open} setSidebarOpen={setSidebarOpen}>
			{/* le logo de la marque */}
			<div className={classes.toolbar}>
				<img className={classes.logo} alt="Administrator Web Access - Aprioris" onClick={() => history.push("/")} src="/static/img/logo-white.png" />
			</div>

			{/* Les différents menus */}
			<Scrollbar>
				<Items>
					<List disablePadding className={classes.list}>
						{routes.map((category, index) => {
							// Les séparateurs horizontaux
							if (category === "------") {
								return <Divider className={classes.divider} key={index} />;
							}
							// Les titres de catégorie, souvent précédés d'un séparateur
							else if (typeof category === "string") {
								return (
									<SidebarSection
										key={index}
										className={clsx({
											[classes.hidden]: !open,
										})}
									>
										{t("translation:sidebar" + pascalCase(category))}
									</SidebarSection>
								);
							}
							// Routes ayant des enfants
							else if (category?.children) {
								let name = t(category.id);

								return (
									<React.Fragment key={index}>
										<Tooltip key={category.id} title={(!open && name) || ""} placement="right">
											<SidebarCategory
												isOpen={!openRoutes[index]}
												isCollapsable
												name={name}
												icon={
													<ListItemIcon
														className={clsx({
															[classes.listItemIconOpen]: open,
															[classes.listItemIconClose]: !open,
														})}
													>
														{category.icon}
													</ListItemIcon>
												}
												button
												onClick={() => toggleOpenRoute(index)}
												className={clsx({
													[classes.listItemOpen]: open,
													[classes.listItemClose]: !open,
												})}
												sidebarOpen={open}
											/>
										</Tooltip>
										<Collapse in={openRoutes[index]} unmountOnExit>
											{category.children.map((route, index) => (
												<SidebarLink key={index} name={t(route.name)} to={route.path} icon={route.icon} badge={route.badge} open={open} />
											))}
										</Collapse>
									</React.Fragment>
								);
							}
							// Routes n'ayant pas d'enfants
							else {
								let name = t(category.id);

								return (
									<Tooltip key={category.id} title={(!open && name) || ""} placement="right">
										<SidebarCategory
											name={name}
											icon={
												<ListItemIcon
													className={clsx({
														[classes.listItemIconOpen]: open,
														[classes.listItemIconClose]: !open,
													})}
												>
													{category.icon}
												</ListItemIcon>
											}
											button
											component={NavLink}
											activeClassName="active"
											to={category.path}
											exact
											className={clsx({
												[classes.listItemOpen]: open,
												[classes.listItemClose]: !open,
											})}
											sidebarOpen={open}
										/>

										{/* <ListItem className={classes.listItem} button component={NavLink} to={category.path}>
											<ListItemIcon
												className={clsx({
													[classes.listItemIconOpen]: open,
													[classes.listItemIconClose]: !open,
												})}
											>
												{category.icon}
											</ListItemIcon>
											<ListItemText
												className={clsx({
													[classes.listItemTextOpen]: open,
													[classes.hidden]: !open,
												})}
												primary={name}
											/>
										</ListItem> */}
									</Tooltip>
								);
							}
						})}
					</List>
				</Items>
			</Scrollbar>
		</ResponsiveDrawer>
	);
}
