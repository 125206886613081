// L'URL de l'API peut être modifiée dans le fichier public/config.js
export const API_URL = window.config.apiUrl;

const KEY = "API_TOKEN";

export const setToken = (token) => localStorage.setItem(KEY, token);

export const getToken = () => localStorage.getItem(KEY);

export const isLoggedIn = () => {
	const token = getToken();
	if (token) return true;
	else {
		removeToken();
		return false;
	}
};

export const removeToken = () => localStorage.removeItem(KEY);

export const parseJwt = (token) => {
	// Récupération du token automatique
	if (typeof token !== "string") token = getToken();

	// Si la personne est connecté
	if (token !== null) {
		var base64Url = token.split(".")[1];
		var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
		var jsonPayload = decodeURIComponent(
			window
				.atob(base64)
				.split("")
				.map(function (c) {
					return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
				})
				.join("")
		);
		return JSON.parse(jsonPayload);
	} else return null;
};
