import { Button, Grid, Typography } from "@material-ui/core";
import React from "react";
import Helmet from "react-helmet";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

function Page404() {
	const history = useHistory();

	const { t } = useTranslation();

	return (
		<>
			<Helmet title={t("translation:notFound")} />

			<Grid container direction="column" alignItems="center" spacing={3}>
				<Grid item xs={12}>
					<Typography component="h1" variant="h1">
						{"404"}
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<Typography component="h2" variant="h5">
						{t("translation:notFound")}
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<Typography component="h2" variant="body1">
						{t("translation:notFoundP")}
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<Button onClick={() => history.push("/")} variant="contained" color="secondary">
						{t("translation:backHome")}
					</Button>
				</Grid>
			</Grid>
		</>
	);
}

export default Page404;
