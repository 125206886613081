/**
 * Convention de nommage :
 * Les icônes finissent par "Icon"
 *
 * Les composants ont le même nom que leurs fichiers
 * Les pages listes finissent par "List"
 * Les pages détails finissent par "Details"
 *
 * Les routes finissent par "Route" quand children est à null
 * ou undefined, "Routes" quand children est un tableau
 *
 * Quand cela est possible, définir la route d'une liste au pluriel,
 * et les details au singulier
 */

import React from "react";
import Async from "../layouts/components/Async";

// #region Import des icônes
import { Assignment, AssignmentInd, Class, Http, PieChart, Settings } from "@material-ui/icons";
import { Users as UsersIcon } from "react-feather";
// #endregion Import des icônes

// #region Imports et définitions des routes du projet
// #region Composants de bases
// -- Imports --
const Login = Async(() => import("../pages/auth/Login"));
// const Register = Async(() => import("../pages/auth/Register"));
const ResetPassword = Async(() => import("../pages/auth/ResetPassword"));
const Page404 = Async(() => import("../pages/auth/Page404"));
const Page500 = Async(() => import("../pages/auth/Page500"));
const PageNetworkError = Async(() => import("../pages/auth/PageNetworkError"));
// -- Définitions --
const authRoutes = {
	id: "auth",
	path: "/auth",
	icon: <UsersIcon />,
	children: [
		{
			path: "/login",
			name: "connexion",
			component: Login,
		},
		// {
		// 	path: "/register",
		// 	name: "inscription",
		// 	component: Register,
		// },
		{
			path: "/reset-password",
			name: "resetPassword",
			component: ResetPassword,
		},
		{
			path: "/404",
			name: "pageNonTrouvée",
			component: Page404,
		},
		{
			path: "/500",
			name: "erreurInterne",
			component: Page500,
		},
		{
			path: "/networkerror",
			name: "erreurRéseau",
			component: PageNetworkError,
		},
	],
};
// #endregion Composants de bases

// #region Racine du site
const Dashboard = Async(() => import("../pages/pages/Dashboard"));
const dashboardRoute = {
	id: "dashboard",
	path: "/",
	icon: <PieChart />,
	component: Dashboard,
	containsHome: true,
};

// #endregion Racine du site

// #region Profil
// -- Imports --
const Profile = Async(() => import("../pages/pages/Profile"));
// -- Définitions --
const profileRoutes = {
	id: "settings",
	path: "/settings",
	icon: <Settings />,
	component: Profile,
};
// #endregion Profil

// #region Suspects
const SuspectsList = Async(() => import("../pages/pages/SuspectsList"));
const suspectsListRoute = {
	id: "suspects-list",
	icon: <AssignmentInd />,
	path: "/suspects",
	component: SuspectsList,
};

const SuspectCard = Async(() => import("../pages/pages/SuspectsCard"));
const suspectCardRoute = {
	id: "suspect-card",
	path: "/suspect/:email",
	component: SuspectCard,
};
// #endregion Suspects

// #region Fichiers
const FilesList = Async(() => import("../pages/pages/FilesList"));
const filesListRoute = {
	id: "files-list",
	icon: <Class />,
	path: "/files",
	component: FilesList,
};

const FileCard = Async(() => import("../pages/pages/FileCard"));
const fileCardRoute = {
	id: "file-card",
	path: "/file/:id",
	component: FileCard,
};
// #endregion Fichiers

// #region Requêtes
const Requests = Async(() => import("../pages/pages/Requests"));
const requestsRoutes = {
	id: "requests",
	path: "/",
	icon: <Http />,
	component: Requests,
	children: [
		{
			id: "all-requests",
			name: "Non filtré",
			path: "/requests",
			component: () => <Requests fetchURL="/requests" />,
		},
		{
			id: "dynamics-solution",
			name: "Dynamics Solution",
			path: "/requests-solution",
			component: () => <Requests fetchURL="/requests/dynamics-solution" />,
		},
		{
			id: "dynamics-academy",
			name: "Dynamics Academy",
			path: "/requests-dynamics",
			component: () => <Requests fetchURL="/requests/dynamics-academy" />,
		},
		{
			id: "except-informatique",
			name: "Except Informatique",
			path: "/requests-except",
			component: () => <Requests fetchURL="/requests/except-informatique" />,
		},
		{
			id: "it-academy",
			name: "IT Academy + Iseries Academy",
			path: "/requests-it-academy",
			component: () => <Requests fetchURL="/requests/it-academy" />,
		},
		{
			id: "atlante",
			name: "Atlante Group",
			path: "/requests-atlante",
			component: () => <Requests fetchURL="/requests/atlante" />,
		},
		{
			id: "calista",
			name: "Calista System",
			path: "/requests-calista",
			component: () => <Requests fetchURL="/requests/calista" />,
		},
	],
};
// #endregion Requêtes

// #region Campagnes
const Prospects = Async(() => import("../pages/pages/Prospects"));
const Campaigns = Async(() => import("../pages/pages/Campaigns"));
const campaignsRoute = {
	id: "campaigns",
	icon: <Assignment />,
	children: [
		{
			id: "campaigns",
			name: "Campagnes",
			path: "/campaigns",
			component: Campaigns,
		},
		{
			id: "prospects-list",
			name: "Ouverture de mails",
			path: "/prospects",
			component: Prospects,
		},
	],
};
// #endregion Campagnes

// #endregion Imports et définitions des routes du projet

// #region Export des routes
// Routes de bases nécessaires au projet
export const auth = [authRoutes];

// Toutes les autres pages utilisées au cours du projet
// L'ordre n'as pas d'importance ici, mais il est toujours bon d'être organisé
export const dashboard = [
	dashboardRoute,
	profileRoutes,
	suspectsListRoute,
	suspectCardRoute,
	filesListRoute,
	fileCardRoute,
	requestsRoutes,
	campaignsRoute,
];

// Définition du menu (sidebar)
// L'ordre ici défini la structure du menu, c'est donc très important !
export default [dashboardRoute, suspectsListRoute, filesListRoute, requestsRoutes, campaignsRoute];
// #endregion Export des routes
